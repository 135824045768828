import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { CustomCircular } from '../../../ui'
import { appRoute } from '../../../constants'
import {
  selectDropdownByNameActiveOnly,
  useCarExpenseGetMutation,
  useCarNoteGetMutation,
  useEditCarMutation,
  useGetCarPaymentMutation,
  useLazyCarsVinReadQuery,
  useLazyGetCarImagesQuery,
} from '../../../services'
import { Form } from 'react-final-form'
import { Header } from '../components/Header'
import { Box, Card, Container, Popover, Tab, Tabs, Typography, generateUtilityClasses, tabsClasses } from '@mui/material'
import { withCars, withPermissionsDropdown } from '../../../hoc'
import Paper from '@mui/material/Paper'
import { AttributesForm, SpecsForm, TitleForm, GPSForm, AssignmentForm, ValuationForm, PurchaseForm, PricingForm } from './components'
import Button from '@mui/material/Button'
import { AccountsDropdownResult, UspCarAddEdit, UspGetDataCar } from '../../../models'
import { objectKeysToLowerCase } from '../../../utils/general'
import removeMask from '../../../utils/removeMask'
import { useNavigate, useParams } from 'react-router-dom'
import { convertDateToString } from '../../../utils/dateUtils'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { useDispatch, useSelector } from 'react-redux'
import ReactRouterPrompt from 'react-router-prompt'
import ConfirmDialog from '../../../components/ConfirmDialog'
import validateFormValues from '../../../utils/validateForm'
import { editInventoryValidation } from '../../../validation'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import Iconify from '../../../components/iconify'
import {
  InventoryList,
  InventoryAdd,
  InventoryEditExpenses,
  InventoryEditNotes,
  InventoryEditFloorplan,
  InventoryEditPictures,
  InventoryEditDocuments,
  InventoryEditPrint,
} from '../index'
import { useSettingsContext } from '../../../components/settings'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AddDepositModal from '../components/AddDepositModal/AddDepositModal'
import { AddFormModal as AddNoteModal, NoteCommentsHeader } from '../InventoryEditNotes/components'
import { AddFormModal as AddExpenseModal } from '../InventoryEditExpenses/components'
import { TNoteRequest } from '../InventoryEditNotes/InventoryEditNotes'
import { TExpenseRequest } from '../InventoryEditExpenses/InventoryEditExpenses'
import { getCar as getCarSelector } from '../../../redux/slices/carsSlice'
import InventoryEditPayments from '../InventoryEditPayments/InventoryEditPayments'
import UploadImageModal from '../InventoryEditPictures/uploadImageModal'

interface Props {
  getCar: (arg: { carId: string }) => void
  carData: any
  carIsSuccess: boolean
  carIsLoading: boolean
}

const TABS = [
  {
    value: 'general',
    label: 'General',
    icon: <Iconify icon='ic:round-home' width={24} />,
  },
  {
    value: 'expenses',
    label: 'Expenses',
    icon: <Iconify icon='maki:car-repair' width={24} />,
  },
  {
    value: 'notes',
    label: 'Notes',
    icon: <Iconify icon='solar:notes-bold' width={24} />,
  },
  {
    value: 'pictures',
    label: 'Pictures',
    icon: <Iconify icon='solar:gallery-wide-bold' width={24} />,
  },
  {
    value: 'payments',
    label: 'Payments',
    icon: <Iconify icon='mdi:account-payment-outline' width={24} />,
  },
  {
    value: 'documents',
    label: 'Documents',
    icon: <Iconify icon='solar:archive-bold' width={24} />,
  },
  {
    value: 'floorplan',
    label: 'Floorplan',
    icon: <Iconify icon='mdi:bank' width={24} />,
  },
]

export const InventoryEdit = ({ getCar, carIsSuccess, carData, carIsLoading }: Props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { carID } = useParams()
  const [openUpload, setOpenUpload] = React.useState<boolean>(false)
  const [getCarImages, { data: carImagesData, isLoading: carImagesIsLoading }] = useLazyGetCarImagesQuery()

  const [carsVin, { data: carsVinData, isLoading: carsVinIsLoading }] = useLazyCarsVinReadQuery()
  const [editCar, { isSuccess: editCarIsSuccess, isLoading: editCarIsLoading }] = useEditCarMutation()
  const [currentVin, setCurrentVin] = useState('')
  const [formChanged, setFormChanged] = useState(false)
  const [initFormValues, setInitFormValues] = useState({})
  const userAccess = useSelector(getUserAccessRights)
  const disableFields = !userAccess?.CanEditCar && carID !== 'add'
  const hideTotalCost = !userAccess?.CanViewCarCost
  const settings = useSettingsContext()
  const [anchorElAdd, setAnchorElAdd] = useState<HTMLButtonElement | null>(null)
  // let formDirty = false
  const [newTabValue, setNewTabValue] = useState('')
  const [confirmSwitchModal, setConfirmSwitchModal] = useState(false)
  const carBodyType = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarBodyType'))

  const handleSubmit = (value: any) => {
    setFormChanged(false)
    editCar({
      carId: carID as string,
      uspCarAddEdit: {
        stockDate: convertDateToString(value?.stockDate),
        branchID: value.branchID,
        stockNumber: value?.stockNumber,
        vin: value?.vIN,
        modelYear: value?.modelYear,
        make: value?.make,
        model: value?.model,
        bodyTypeID: value?.bodyTypeID,
        modelTrim: value?.modelTrim,
        mileage: value?.mileage,
        fuelTypeID: value?.fuelTypeID,
        cylinders: value?.cylinders,
        isAutoMarkup: value?.isAutoMarkup,
        carStatusID: value?.carStatusID,
        purchaseType: value?.purchaseType,
        softPack: removeMask(value?.softPack),
        bookValue1: removeMask(value?.bookValue1),
        bookValue2: removeMask(value?.bookValue2),
        stickerPrice: removeMask(value?.stickerPrice),
        onlinePrice: removeMask(value?.onlinePrice),
        wholesalePrice: removeMask(value?.wholesalePrice),
        downPayment: removeMask(value?.downPayment),
        lotID: value?.lotID,
        location: value?.location,
        conditionID: value?.conditionID,
        finderID: value?.finderID,
        tagNumber: value?.tagNumber,
        titleTypeID: value?.titleTypeID,
        numberOfKeys: value?.numberOfKeys,
        note: value?.note,
        isTitleInHand: value?.isTitleInHand,
        titleState: value?.titleState,
        titleNumber: value?.titleNumber,
        titleIssueDate: convertDateToString(value?.titleIssueDate),
        engine: value?.engine,
        transmission: value?.transmission,
        exteriorColor: value?.exteriorColor,
        interiorColor: value?.interiorColor,
        driveTrainID: value?.drivetrainID,
        cityMPG: value?.cityMPG,
        hwyMPG: value?.hwyMPG,
        weight: value?.weight,
        isGPS: value?.isGPS,
        isSID: value?.isSID,
        gpsSidVendor: value?.gpsSidVendor,
        gpsSidType: value?.gpsSidType,
        gpsSidSerialNumber: value?.gpsSidSerialNumber,
        isSmogCompleted: value?.isSmogCompleted,
        odometerTypeID: value?.odometerTypeID,
        serviceNote: value?.serviceNote,
        comment: value?.comment,
      } as UspCarAddEdit,
    })
  }

  useEffect(() => {
    if (editCarIsSuccess) {
      getCar({ carId: carID as string })
      dispatch(enqueueNotification({ message: 'Edit successful' }))
    }
  }, [editCarIsSuccess])

  useEffect(() => {
    getCar({ carId: carID as string })
    getCarImages({ carId: carID as string })
  }, [])

  useEffect(() => {
    if (currentVin) {
      carsVin({ vin: currentVin })
    }
  }, [currentVin])

  useEffect(() => {
    const bodyType = carBodyType?.find(({ BodyType }) => BodyType === carsVinData?.BodyType)?.ID
    const carDataBasedInitialValues = objectKeysToLowerCase(carData)
    console.log(carDataBasedInitialValues)
    setInitFormValues({
      ...carDataBasedInitialValues,
    })

    if (carsVinData) {
      const vinBasedInitialValues = {
        modelYear: carsVinData?.ModelYear,
        make: carsVinData?.Make,
        vIN: currentVin,
        model: carsVinData?.Model,
        modelTrim: carsVinData?.ModelTrim,
        bodyType: bodyType,
        engine: carsVinData?.Engine,
        fuelType: carsVinData?.FuelType,
        doors: carsVinData?.Doors,
        drivetrain: carsVinData?.Drivetrain,
        cylinders: carsVinData?.Cylinders,
        bodyTypeID: carsVinData?.BodyTypeID,
        fuelTypeID: carsVinData?.FuelTypeID,
        driveTrainID: carsVinData?.DriveTrainID,
      }
      setInitFormValues({
        ...carDataBasedInitialValues,
        ...vinBasedInitialValues,
      })
    }
  }, [carsVinData, carData])

  const [currentTab, setCurrentTab] = useState('general')

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    if (formChanged) {
      setNewTabValue(newValue)
      setConfirmSwitchModal(true)
    } else {
      setCurrentTab(newValue)
    }
  }

  const openAdd = Boolean(anchorElAdd)
  const id = openAdd ? 'add-menu-popover' : undefined

  const handleOpenAddMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElAdd(event.currentTarget)
  }

  const handleCloseAddMenu = () => {
    setAnchorElAdd(null)
  }

  const [openNoteModal, setOpenNoteModal] = useState(false)
  const [openExpenseModal, setOpenExpenseModal] = useState(false)
  const [openDepositModal, setOpenDepositModal] = useState(false)

  const handleDepositModalOpen = () => setOpenDepositModal(true)
  const handleDepositModalClose = () => {
    setFormChanged && setFormChanged(false)
    setOpenDepositModal(false)
  }

  const handleNoteModalOpen = () => setOpenNoteModal(true)
  const handleNoteModalClose = () => {
    setFormChanged && setFormChanged(false)
    setOpenNoteModal(false)
  }

  const handleExpenseModalOpen = () => setOpenExpenseModal(true)
  const handleExpenseModalClose = () => {
    setFormChanged && setFormChanged(false)
    setOpenExpenseModal(false)
  }

  const [getCarPayment, { data, isLoading }] = useGetCarPaymentMutation()

  const [noteRequestData, setNoteRequestData] = useState<TNoteRequest>({
    isIncludeAllHistoryByCar: true,
    isIncludeCancelledNotes: true,
  })

  const [getCarNote, { data: carNoteData, isLoading: carNoteIsLoading }] = useCarNoteGetMutation()
  const [expenseRequestData, setExpenseRequestData] = useState<TExpenseRequest>({
    isIncludeAllHistoryByCar: true,
    isIncludeReversals: true,
  })

  const [getCarExpense, { data: carExpenseData, isLoading: carExpenseIsLoading }] = useCarExpenseGetMutation()

  const car: UspGetDataCar = useSelector(getCarSelector(carID))

  return (
    <Container
      maxWidth={settings.themeStretch ? false : 'lg'}
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <CustomBreadcrumbs
        heading='Inventory'
        links={[
          {
            name: 'List',
            href: appRoute.Inventory,
          },
          {
            name: `Stock # ${carData?.StockNumber} | ${carData?.CarDescription} ${carData?.VIN}`,
          },
        ]}
        action={
          <>
            <Button aria-describedby={id} variant='contained' onClick={handleOpenAddMenu} endIcon={<KeyboardArrowDownIcon />}>
              + Add
            </Button>
            <Popover
              id={id}
              open={openAdd}
              anchorEl={anchorElAdd}
              onClose={handleCloseAddMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Grid item style={{ display: 'flex', flexDirection: 'column', width: '150px' }}>
                {!disableFields && (
                  <Button
                    style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
                    onClick={() => {
                      handleExpenseModalOpen()
                      handleCloseAddMenu()
                    }}
                  >
                    Expense
                  </Button>
                )}
                <Button
                  style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
                  onClick={() => {
                    handleNoteModalOpen()
                    handleCloseAddMenu()
                  }}
                >
                  Note
                </Button>
                {carData?.IsAvailable && !carData?.IsDeleted && (
                  <Button
                    style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
                    onClick={() => {
                      handleDepositModalOpen()
                      handleCloseAddMenu()
                    }}
                  >
                    Deposit
                  </Button>
                )}
                <Button style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}>Document</Button>
                <Button
                  onClick={() => {
                    setOpenUpload(true)
                    handleCloseAddMenu()
                  }}
                  style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
                >
                  Pictures
                </Button>
              </Grid>
            </Popover>
          </>
        }
        sx={{
          mb: {
            xs: 1,
            md: 3,
          },
        }}
      />

      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        sx={{
          mb: 1,
          [`& .${tabsClasses.flexContainer}`]: {
            pr: 3,
          },
        }}
      >
        {TABS.map((tab) => {
          if (tab.value === 'floorplan') {
            if (car?.IsShowFloorplan) {
              return <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
            } else {
              return false
            }
          } else {
            return <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
          }
        })}
      </Tabs>

      <Form keepDirtyOnReinitialize onSubmit={handleSubmit} initialValues={initFormValues} validate={validateFormValues(editInventoryValidation)}>
        {({ handleSubmit, form, dirty }: any) => {
          // formDirty = dirty
          return (
            <>
              {currentTab === 'general' && (
                <form onSubmit={handleSubmit} onChange={() => setFormChanged(true)}>
                  <ReactRouterPrompt when={formChanged} beforeConfirm={async () => {}}>
                    {({ isActive, onConfirm, onCancel }) => (
                      <ConfirmDialog
                        open={!!isActive}
                        onOk={onConfirm}
                        handleClose={onCancel}
                        notification={'Warning: Your changes haven’t been saved.'}
                      />
                    )}
                  </ReactRouterPrompt>

                  <Box
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 3,
                    }}
                  >
                    <Card
                      sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        p: 4,
                        gap: 3,
                      }}
                    >
                      <Typography variant='h6' gutterBottom>
                        Specs
                      </Typography>

                      <AttributesForm disableFields={disableFields} setCurrentVin={setCurrentVin} onDropdownFormChange={() => setFormChanged(true)} />
                      <SpecsForm disableFields={disableFields} onDropdownFormChange={() => setFormChanged(true)} />
                    </Card>

                    <Card
                      sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        p: 4,
                        gap: 3,
                      }}
                    >
                      <Typography variant='h6' gutterBottom>
                        Assignment
                      </Typography>

                      <AssignmentForm disableFields={disableFields} onDropdownFormChange={() => setFormChanged(true)} />
                    </Card>

                    <Card
                      sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        p: 4,
                        gap: 3,
                      }}
                    >
                      <Typography variant='h6' gutterBottom>
                        Purchase
                      </Typography>

                      <PurchaseForm
                        hideTotalCost={hideTotalCost}
                        disableFields={disableFields}
                        carData={carData}
                        onDropdownFormChange={() => setFormChanged(true)}
                      />
                    </Card>

                    <Card
                      sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        p: 4,
                      }}
                    >
                      <PricingForm disableFields={disableFields} onDropdownFormChange={() => setFormChanged(true)} />
                    </Card>

                    <Card
                      sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        p: 4,
                      }}
                    >
                      <ValuationForm disableFields={disableFields} onDropdownFormChange={() => setFormChanged(true)} />
                    </Card>

                    <Card
                      sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        p: 4,
                      }}
                    >
                      <TitleForm disableFields={disableFields} onDropdownFormChange={() => setFormChanged(true)} />
                    </Card>

                    <Card
                      sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        p: 4,
                      }}
                    >
                      <GPSForm disableFields={disableFields} onDropdownFormChange={() => setFormChanged(true)} />
                    </Card>
                    <Card
                      sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        p: 4,
                      }}
                    >
                      <NoteCommentsHeader handleFormChange={() => setFormChanged(true)} />
                    </Card>
                    <Box position={'sticky'} sx={{ bottom: 20, right: 20 }} display='flex' justifyContent='flex-end' gap={2}>
                      <Button variant='contained' color='error' onClick={() => navigate(appRoute.Inventory)}>
                        Close
                      </Button>
                      <Button type='submit' disabled={!formChanged} variant='contained'>
                        Save
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
              <AddDepositModal
                carIsSuccess={carIsSuccess as boolean}
                carData={carData}
                getCar={getCar as () => void}
                getCarPaymentTableData={getCarPayment as any}
                openModal={openDepositModal}
                handleClose={handleDepositModalClose}
                setFormChanged={setFormChanged}
              />
              <AddNoteModal
                noteRequestData={noteRequestData as TNoteRequest}
                getCarNote={getCarNote || (() => {})}
                open={openNoteModal}
                onClose={handleNoteModalClose}
                setFormChanged={setFormChanged}
                formChanged={formChanged as boolean}
              />
              <AddExpenseModal
                expenseRequestData={expenseRequestData as TExpenseRequest}
                getCarExpense={getCarExpense || (() => {})}
                carData={carData}
                open={openExpenseModal}
                onClose={handleExpenseModalClose}
                setFormChanged={setFormChanged}
                formChanged={formChanged as boolean}
                getCar={getCar as () => void}
                onDropdownFormChange={() => true}
              />
            </>
          )
        }}
      </Form>

      <UploadImageModal
        refresh={() => getCarImages({ carId: carID?.toString() as string })}
        carId={(carID ?? '').toString() as string}
        open={openUpload}
        onClose={() => {
          setOpenUpload(false)
        }}
      />
      {currentTab === 'expenses' && (
        <InventoryEditExpenses getCarExpense={getCarExpense} carExpenseData={carExpenseData} carExpenseIsLoading={carExpenseIsLoading} />
      )}

      {currentTab === 'notes' && <InventoryEditNotes getCarNote={getCarNote} carNoteData={carNoteData} carNoteIsLoading={carNoteIsLoading} />}

      {currentTab === 'pictures' && (
        <InventoryEditPictures
          onDropdownFormChange={(val: boolean) => setFormChanged(val)}
          getCarImages={getCarImages}
          carImagesData={carImagesData}
          carImagesIsLoading={carImagesIsLoading}
        />
      )}

      {currentTab === 'documents' && <InventoryEditDocuments />}

      {currentTab === 'payments' && <InventoryEditPayments />}
      {currentTab === 'floorplan' && <InventoryEditFloorplan />}

      <ConfirmDialog
        open={confirmSwitchModal}
        onOk={() => {
          setConfirmSwitchModal(false)
          setFormChanged(false)
          setCurrentTab(newTabValue)
        }}
        handleClose={() => setConfirmSwitchModal(false)}
        notification={'All the changes made would be lost'}
      />
    </Container>

    // <CustomCircular open={isLoading} />
  )
}

export default withPermissionsDropdown(withCars(InventoryEdit))
