import React from 'react'
import {
  Grid,
  generateUtilityClasses,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  TableSortLabel,
  Tooltip,
  TablePagination,
  Box,
} from '@mui/material'
import { CircularProgress } from '@mui/material'
import { UspCarSearchResult, UspGetDataDealEntryResult } from '../../../../../../../models'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import { convertDateToDisplay } from '../../../../../../../utils/dateUtils'
import { storageGet, storageSet } from '../../../../../../../utils/storage'
import { getComparator, stableSort } from '../../../../../../../utils/sorting'
import { useParams } from 'react-router-dom'
import { setCar } from '../../../../../../../redux/slices/carsSlice'
import { useDispatch } from 'react-redux'
import Iconify from '../../../../../../../components/iconify'

interface Props {
  dealData: UspGetDataDealEntryResult
  searchCarsData?: UspCarSearchResult[]
  loading: boolean
  setSelectedCar: (car?: UspCarSearchResult) => void
  setCurrentStage: (stage: 'inventory' | 'credit' | 'dealStructure' | 'dealResult') => void
}

export const InventoryTableBlock = ({ dealData, searchCarsData, loading, setSelectedCar, setCurrentStage }: Props) => {
  const { dealID } = useParams()
  const dispatch = useDispatch()
  const initialOrder = (storageGet('select_inventory_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('select_inventory_order_by') || 'StockNumber') as keyof UspCarSearchResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspCarSearchResult>(initialOrderBy)

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const paginatedData = searchCarsData

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSelectCar = (car?: UspCarSearchResult) => {
    setCurrentStage('dealStructure')
    setSelectedCar(car)
    dispatch(setCar(car))
  }

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspCarSearchResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('select_inventory_order', isAsc ? 'desc' : 'asc')
    storageSet('select_inventory_order_by', property)
  }

  const createSortHandler = (property: keyof UspCarSearchResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'StockNumber'}
                  direction={orderBy === 'StockNumber' ? order : 'asc'}
                  onClick={createSortHandler('StockNumber')}
                >
                  Stock #
                </TableSortLabel>
              </TableCell>
              <TableCell>
                {' '}
                <TableSortLabel
                  active={orderBy === 'Vehicle'}
                  direction={orderBy === 'Vehicle' ? order : 'asc'}
                  onClick={createSortHandler('Vehicle')}
                >
                  Description
                </TableSortLabel>
              </TableCell>
              <TableCell>
                {' '}
                <TableSortLabel active={orderBy === 'VIN'} direction={orderBy === 'VIN' ? order : 'asc'} onClick={createSortHandler('VIN')}>
                  VIN
                </TableSortLabel>
              </TableCell>
              <TableCell>
                {' '}
                <TableSortLabel active={orderBy === 'Color'} direction={orderBy === 'Color' ? order : 'asc'} onClick={createSortHandler('Color')}>
                  Color
                </TableSortLabel>
              </TableCell>
              <TableCell>
                {' '}
                <TableSortLabel
                  active={orderBy === 'Mileage'}
                  direction={orderBy === 'Mileage' ? order : 'asc'}
                  onClick={createSortHandler('Mileage')}
                >
                  Mileage
                </TableSortLabel>
              </TableCell>
              <TableCell>
                {' '}
                <TableSortLabel
                  active={orderBy === 'CarStatus'}
                  direction={orderBy === 'CarStatus' ? order : 'asc'}
                  onClick={createSortHandler('CarStatus')}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell>
                {' '}
                <TableSortLabel
                  active={orderBy === 'StockDate'}
                  direction={orderBy === 'StockDate' ? order : 'asc'}
                  onClick={createSortHandler('StockDate')}
                >
                  Stocked
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'BranchCode'}
                  direction={orderBy === 'BranchCode' ? order : 'asc'}
                  onClick={createSortHandler('BranchCode')}
                >
                  Branch
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'IsAvailable'}
                  direction={orderBy === 'IsAvailable' ? order : 'asc'}
                  onClick={createSortHandler('IsAvailable')}
                >
                  Available
                </TableSortLabel>
              </TableCell>
              <TableCell align={'right'}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(getComparator(order, orderBy), paginatedData as any)
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((item) => (
                <TableRow key={item.CarID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                  <TableCell onClick={() => handleSelectCar(item as any)}>{item.StockNumber}</TableCell>
                  <TableCell onClick={() => handleSelectCar(item as any)}>{item?.Vehicle}</TableCell>
                  <TableCell onClick={() => handleSelectCar(item as any)}>{item?.VIN}</TableCell>
                  <TableCell onClick={() => handleSelectCar(item as any)}>{item?.Color}</TableCell>
                  <TableCell onClick={() => handleSelectCar(item as any)}>{item?.Mileage}</TableCell>
                  <TableCell onClick={() => handleSelectCar(item as any)}>{item?.CarStatus}</TableCell>
                  <TableCell onClick={() => handleSelectCar(item as any)}>{convertDateToDisplay(item?.StockDate as string)}</TableCell>
                  <TableCell onClick={() => handleSelectCar(item as any)}>{item?.BranchCode}</TableCell>
                  <TableCell onClick={() => handleSelectCar(item as any)}>
                    {item.IsAvailable ? <Typography>Yes</Typography> : <Typography>No</Typography>}
                  </TableCell>
                  <TableCell align={'right'}>
                    <Tooltip title='Select'>
                      <IconButton onClick={() => handleSelectCar(item as any)}>
                        <Iconify icon='ph:paperclip-light' />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            {searchCarsData?.length === 0 && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  No results
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ width: '100%', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
        <Paper>
          <TablePagination
            component='div'
            count={searchCarsData?.length as number}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  )
}
