import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { Header } from '../components/Header'
import { FormControlLabel, generateUtilityClasses, IconButton, Modal, Stack, Switch, Typography } from '@mui/material'
import { withCars } from '../../../hoc'
import { useDispatch } from 'react-redux'
import { useDeleteCarImageMutation, useLazyGetCarImagesQuery, usePostCarImagesMutation, useUploadCarImageMutation } from '../../../services'
import Button from '@mui/material/Button'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { CustomCircular } from '../../../ui'
import ConfirmDialog from '../../../components/ConfirmDialog'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import UploadImageModal from './uploadImageModal'
import { SortableItem } from './sortableItem'
import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, rectSortingStrategy, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { LoadingButton } from '@mui/lab'

const classes = generateUtilityClasses('Grid', ['root', 'pageBody', 'titleWrap'])

const StyledGrid = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(0, 4, 2, 4),
  },
  [`& .${classes.titleWrap}`]: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    paddingLeft: '0px',
    margin: theme.spacing(2, 2, 2, 0),
  },
}))

const HoverButtons = styled('div')(({ theme }) => ({
  display: 'none',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
}))

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  width: '120px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '10px',
  textAlign: 'left',
  border: '1px solid transparent',
  '&:hover': {
    border: '1px solid white',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
}))

const StyledImageListItem = styled('div')(({ theme }) => ({
  display: 'block',
  position: 'relative',
  paddingBottom: '100%',
  '& img': {
    position: 'absolute',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
  },
}))

const StyledModal = styled('div')(({ theme }) => ({
  background: 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '80vw',
  height: '80vh',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}))

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  top: theme.spacing(0),
  right: theme.spacing(0),
  color: theme.palette.grey[500],
  zIndex: 1301,
  padding: 0,
  margin: 0,
}))

const EnlargedImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
})

const ImageWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  '&:hover': {
    '& .Overlay': {
      opacity: 0.5,
    },
    '& .HoverButtons': {
      display: 'flex',
      gap: theme.spacing(1),
      flexDirection: 'column',
      zIndex: 2,
    },
  },
}))

const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  opacity: 0,
  transition: 'opacity 0.3s ease',
  borderRadius: '5px',
})

export const InventoryEditPictures = (props: any) => {
  const { getCarImages, carImagesData, carImagesIsLoading, onDropdownFormChange } = props
  const { carID } = useParams()
  const dispatch = useDispatch()
  const [reorder, setReorder] = useState(false)
  const [imageList, setImageList] = useState(carImagesData)
  const [postCarImages, { isLoading: postCarImagesIsLoading, isSuccess: postCarImagesIsSuccess }] = usePostCarImagesMutation()

  useEffect(() => {
    if (carImagesData !== imageList) {
      setImageList(carImagesData)
    }
  }, [carImagesData])
  useEffect(() => {
    if (postCarImagesIsSuccess) {
      onDropdownFormChange(false)
      dispatch(
        enqueueNotification({
          message: 'Order Updated Successfully',
          variant: 'success',
        }),
      )
      getCarImages({ carId: carID?.toString() as string })
    }
  }, [postCarImagesIsLoading, postCarImagesIsSuccess])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event
    onDropdownFormChange(true)
    if (over && active.id !== over.id) {
      setImageList((items: any) => {
        const oldIndex = items.findIndex((item: any) => item.CarImageID === active.id)
        const newIndex = items.findIndex((item: any) => item.CarImageID === over.id)

        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  return (
    <StyledGrid className={classes.root}>
      {/* <Header
        modelYear={carData?.ModelYear}
        make={carData?.Make}
        model={carData?.Model}
        vin={carData?.VIN}
        stockNumber={carData?.StockNumber}
        carIsSuccess={props?.carIsSuccess}
        carData={props?.carData}
        getCar={props?.getCar}
        formChanged={formChanged}
        setFormChanged={setFormChanged}
        onDropdownFormChange={() => setFormChanged(true)}
      /> */}
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={1} columnSpacing={1} className={classes.pageBody}>
        <Grid item xs={12}>
          {carImagesData && carImagesData.length > 0 && (
            <FormControlLabel
              sx={{ pb: 2 }}
              control={
                <Switch
                  checked={reorder}
                  onChange={(e) => {
                    setImageList(carImagesData)

                    setReorder(e.target.checked)
                  }}
                />
              }
              label='Reorder'
            />
          )}
        </Grid>

        <Grid container gap={2}>
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={imageList.map((el: any) => el.CarImageID)} strategy={rectSortingStrategy}>
              {(imageList || []).map((item: any) => (
                <SortableItem reorder={reorder} getCarImages={getCarImages} image={item} id={item.CarImageID} />
              ))}
            </SortableContext>
          </DndContext>
        </Grid>
      </Grid>
      {reorder && (
        <Stack direction={'row'} spacing={2} justifyContent={'flex-end'}>
          <Button
            color='inherit'
            variant='outlined'
            onClick={() => {
              setImageList(carImagesData)
              setReorder(false)
              onDropdownFormChange(false)
            }}
          >
            Cancel
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            onClick={() => {
              postCarImages({
                carId: carID,
                imageOrderData: {
                  imageOrderData: imageList.map((el: any, index: any) => {
                    return { CarImageID: el.CarImageID, ImageOrder: index++ }
                  }),
                },
              })
              setReorder(false)
            }}
            loading={postCarImagesIsLoading}
          >
            Save
          </LoadingButton>
        </Stack>
      )}
    </StyledGrid>
  )
}

export default withCars(InventoryEditPictures)
