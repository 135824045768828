import React, { useEffect, useRef, useState } from 'react'
import {
  UspCarSearchResult,
  UspCreditAppSearchResult,
  UspDealAddEditPickupDate,
  UspDealAddEditFeeData,
  UspDealAddEditProductData,
  SearchLenderApiResponse,
  UspListLender,
  UspGetDataCreditApp,
  UspGetDataDealEntryResult,
  UspGetDataCar,
  CalculateDealResult,
} from '../../../../../models'
import { selectDropdownByNameActiveOnly, useAddDealMutation, useGetDealLenderListQuery } from '../../../../../services'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import { Box, Card, generateUtilityClasses } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Header, ResultForm, FinancingForm, SettingsForm } from './components'
import Button from '@mui/material/Button'
import { appRoute } from '../../../../../constants'
import validateForm from '../../../../../utils/validateForm'
import { addDealResultValidation } from '../../../../../validation'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Paper from '@mui/material/Paper'
import { convertDateToString } from '../../../../../utils/dateUtils'
import removeMask from '../../../../../utils/removeMask'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { SplitButton } from '../../../../../ui'
import { setCar } from '../../../../../redux/slices/carsSlice'
import Recap from '../DealStructure/components/Recap'
interface Props {
  setCurrentStage: (stage: 'inventory' | 'credit' | 'dealStructure' | 'dealResult') => void
  setSelectedCar: (car?: UspCarSearchResult) => void
  setSelectedCreditApp: (creditApp?: UspCreditAppSearchResult | UspGetDataCreditApp) => void
  carSelected?: any // UspGetDataCar | UspCarSearchResult
  creditSelected?: UspCreditAppSearchResult | UspGetDataCreditApp
  dealStructureFormValues?: any
  editDealIsSuccess: boolean
  totalPickups: string | number
  editDeal: (e: any) => void
  dealType: string | undefined | null
  disableFields: boolean
  dealData: UspGetDataDealEntryResult | undefined
  carData?: UspGetDataCar
  setIsDownPaymentDeleteCalled?: any
  calculationForDealData: CalculateDealResult | undefined
}

export const DealResult = ({
  setCurrentStage,
  carSelected,
  creditSelected,
  setSelectedCar,
  setSelectedCreditApp,
  dealStructureFormValues,
  editDealIsSuccess,
  totalPickups,
  editDeal,
  dealType,
  disableFields,
  dealData,
  carData,
  calculationForDealData,
}: Props) => {
  const branchID = localStorage.getItem('branch')
  const { dealID } = useParams()
  const [initialValues, setInitialValues] = useState<any>(
    dealID === 'add' ? { ...dealStructureFormValues, BranchID: Number(branchID) } : { ...dealStructureFormValues },
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()
  const handleSubmitRef = useRef<any>(null)

  const [addDeal, { data: addDealData, isSuccess: addDealIsSuccess }] = useAddDealMutation()
  const { data: dealLenderList } = useGetDealLenderListQuery({})
  const companyType = localStorage.getItem('companyType')
  const lenderListDate = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Lender'))

  const [selectedLender, setSelectedLender] = useState<any>({})
  const [isSaveAndCloseClicked, setIsSaveAndCloseClicked] = useState(false)
  const areFieldsVisible =
    (Object?.keys(selectedLender)?.length === 0 && dealID === 'add') ||
    companyType?.toLowerCase()?.trim() === selectedLender?.ServicedIn?.toLowerCase()?.trim()

  const handleSubmit = (values: any) => {
    console.log(values)
    const jsonPickupData: UspDealAddEditPickupDate[] = []
    Object?.keys(values)?.forEach((item: string) => {
      if (item?.includes('DuePaymentDate')) {
        const id = item?.replace('DuePaymentDate', '')
        if (values[`DuePaymentDate${id}`] && values[`DatePaymentAmount${id}`]) {
          jsonPickupData.push({
            PickupAmount: Number(removeMask(values[`DatePaymentAmount${id}`])),
            PickupDueDate: convertDateToString(values[`DuePaymentDate${id}`]) as string,
          })
        }
      }
    })

    const jsonFeeData: UspDealAddEditFeeData[] = []
    Object?.keys(values)?.forEach((item: string) => {
      if (item?.includes('FeeFieldValue')) {
        const id = item?.replace('FeeFieldValue', '')
        if (values[`FeeFieldValue${id}`] && Number(removeMask(values[`FeeFieldValue${id}`])) !== 0) {
          jsonFeeData.push({
            StateFeeID: Number(id),
            FeePrice: Number(removeMask(values[`FeeFieldValue${id}`])) || 0,
          })
        }
      }
    })

    const jsonProductData: UspDealAddEditProductData[] = []
    Object?.keys(values)?.forEach((item: string) => {
      if (item?.includes('ProductFieldValue')) {
        const id = item?.replace('ProductFieldValue', '')
        if (values[`ProductFieldValue${id}`] && Number(removeMask(values[`ProductFieldValue${id}`])) !== 0) {
          jsonProductData.push({
            StateProductID: Number(id),
            ProductPrice: Number(removeMask(values[`ProductFieldValue${id}`])) || 0,
          })
        }
      }
    })

    // let contactID1: number | null
    // let contactID2: number | null
    // if (creditSelected?.CreditAppID === null && creditSelected?.ContactID1 !== null) {
    //   contactID1 = creditSelected?.ContactID1
    // } else {
    //   contactID1 = null
    // }
    // if (creditSelected?.CreditAppID === null && creditSelected?.ContactID2 !== null) {
    //   contactID2 = creditSelected?.ContactID2
    // } else {
    //   contactID2 = null
    // }

    const uspDealAddEdit = {
      contactID1: creditSelected?.ContactID1,
      contactID2: creditSelected?.ContactID2,
      branchID: values?.BranchID,
      dealType: values?.DealType,
      dealState: values?.DealState,
      dealDate: convertDateToString(values?.DealDate),
      firstDueDate: convertDateToString(values?.FirstDueDate),
      paymentSchedule: values?.Schedule,
      term: values?.AmountFinalTerm,
      paymentAmount: removeMask(values?.AmountPaymentAmount),
      carID: carSelected?.CarID,
      lenderID: values?.LenderID,
      creditAppID: creditSelected?.CreditAppID,
      maturityDate: convertDateToString(values?.MaturityDate),
      salesPrice: removeMask(values?.SalesPrice),
      jsonPickupData: values?.DealType == 'Cash' ? null : jsonPickupData,
      salesDiscountAmount: areFieldsVisible ? '0' : removeMask(values?.DiscountAmount?.toString()),
      spreadAmount: areFieldsVisible ? '0' : removeMask(values?.SpreadAmount),
      spreadRate: values?.SpreadRate,
      cashDownAmount: values?.DealType === 'Wholesale' ? '0' : removeMask(values?.CashDown),
      interestMethod: 'IB', // TODO FIX TEMPORARY SOLUTION
      interestRebateMethod: 'IB',
      finalPaymentAmount: removeMask(values?.AmountFinalPayment),
      interestRate: values?.InterestRate,
      apr: values?.APRRate,
      amountFinanced: removeMask(values?.AmountFinanced),
      totalPayments: removeMask(values?.TotalOfPayments),
      financeCharges: removeMask(values?.AmountFinanceCharges),
      tradeAllowance: removeMask(values?.TradeAllowance),
      tradeAcv: removeMask(values?.TradeACV),
      tradeLien: removeMask(values?.TradeLien),
      jsonFeeData: values?.DealType === 'Wholesale' ? null : jsonFeeData,
      jsonProductData: jsonProductData,
      taxState: values?.TaxState,
      stateTaxRate: values?.StateTaxRate,
      cityTaxRate: values?.CityTaxRate,
      countyTaxRate: values?.CountyTaxRate,
      miscTaxRate: values?.MiscTaxRate,
      stateTaxAmount: removeMask(values?.StateTaxAmount),
      cityTaxAmount: removeMask(values?.CityTaxAmount),
      countyTaxAmount: removeMask(values?.CountyTaxAmount),
      miscTaxAmount: removeMask(values?.MiscTaxAmount),
      dealPostalCode: values?.DealPostalCode,
      taxCityName: values?.TaxCity,
      taxCountyName: values?.TaxCounty,
      dueDay1: values?.DueDay1,
      dueDay2: values?.DueDay2,
      docStamps: values?.DocStampsAmount,
      dealStatusID: values?.DealStatus,
      salespersonUserID: values.Salesperson,
      fiUserID: values?.Manager,
      jsonConsumerLoanPayableList: null,
      isApplyDepositToDeal: false,
      isRefinance: false,
      isSystemGenerated: false,
    }

    if (dealID === 'add') {
      addDeal({
        uspDealAddEdit: uspDealAddEdit as any,
      })
    } else {
      editDeal({
        dealId: dealID as string,
        uspDealAddEdit: uspDealAddEdit as any,
      })
    }
  }

  useEffect(() => {
    if (addDealIsSuccess) {
      // USE STORE PRESIGERE MESSAGE
      dispatch(enqueueNotification({ message: 'Deal has been created' }))

      // Reset state if user added Credit App while selecting credit app from Deals -> Add New / Edit
      dispatch(setCar(undefined))
      if (state?.redirectedFrom) {
        state.redirectedFrom = undefined
      }

      if (isSaveAndCloseClicked) {
        navigate(appRoute?.DealsList)
      } else {
        navigate(appRoute?.DealsTask.replace(':dealID', addDealData?.ID?.toString() as string))
      }
    }
  }, [addDealIsSuccess])

  useEffect(() => {
    if (editDealIsSuccess) {
      dispatch(enqueueNotification({ message: 'Deal have been updated' }))
      if (isSaveAndCloseClicked) {
        navigate(appRoute?.DealsList)
      } else {
        navigate(appRoute?.DealsTask.replace(':dealID', dealID as string))
      }
    }
  }, [editDealIsSuccess])

  const splitOptions = ['Save & Continue', 'Save & Close']
  const splitFunctions = [
    () => {
      handleSubmitRef.current && handleSubmitRef.current()
    },
    () => {
      setIsSaveAndCloseClicked(true)
      handleSubmitRef.current && handleSubmitRef.current()
    },
  ]
  const types: Array<'button' | 'submit' | 'reset' | undefined> = ['submit', 'submit']

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues} validate={validateForm(addDealResultValidation(dealType))}>
      {({ handleSubmit }: any) => {
        handleSubmitRef.current = handleSubmit

        return (
          <>
            <Box
              sx={{
                flexGrow: { md: 1 },
                display: { md: 'flex' },
                flexDirection: { md: 'column' },
                gap: 3,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Card
                    sx={{
                      flexGrow: { md: 1 },
                      display: { md: 'flex' },
                      flexDirection: { md: 'column' },
                      gap: 3,
                    }}
                  >
                    <ResultForm itemizationData={calculationForDealData?.DealItemizationData} dealType={dealType} />
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {dealType === 'Financed' && (
                    <Card
                      sx={{
                        flexGrow: { md: 1 },
                        display: { md: 'flex' },
                        flexDirection: { md: 'column' },
                        p: 4,
                        gap: 3,
                      }}
                    >
                      <FinancingForm
                        disableFields={disableFields}
                        dealLenderList={dealLenderList as UspListLender[]}
                        totalPickups={totalPickups}
                        setSelectedLender={setSelectedLender}
                        areFieldsVisible={areFieldsVisible as boolean}
                        lenderListDate={lenderListDate as SearchLenderApiResponse}
                      />
                    </Card>
                  )}
                  <Card
                    sx={{
                      flexGrow: { md: 1 },
                      display: { md: 'flex' },
                      flexDirection: { md: 'column' },
                      p: 4,
                      gap: 3,
                      mt: dealType === 'Financed' ? 3 : 0,
                    }}
                  >
                    <SettingsForm disableFields={disableFields} managerID={dealData?.FiUserID} salesPersonID={dealData?.SalespersonUserID} />
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <Box display='flex' flexWrap={'wrap'} justifyContent='flex-end' gap={2} mt={3}>
              <Button startIcon={<ArrowBackIosIcon />} onClick={() => setCurrentStage('dealStructure')}>
                Back
              </Button>
              <Recap carData={carData} dealData={dealData} />

              <SplitButton options={splitOptions} functions={splitFunctions} types={types} />
            </Box>
          </>
        )
      }}
    </Form>
  )
}

export default DealResult
