import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Modal,
  Paper,
  Switch,
  Typography,
  generateUtilityClasses,
} from '@mui/material'
import { styled, width } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { AmountField, DateField, TextField } from '../../../../../ui'
import { useParams } from 'react-router-dom'
import { fetchCurrentDateTimeFromServer, formatNumber, getCurrentDate } from '../../../../../utils/general'
import { convertDateToString } from '../../../../../utils/dateUtils'
import { useGetAccountDueAmountMutation, useGetTimeMutation } from '../../../../../services'
import { AccountDueAmountResult } from '../../../../../models'

const classes = generateUtilityClasses('Box', ['root', 'row', 'formItem', 'formItemCalculation', 'submitBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: theme.palette.background.secondary,
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.formItem}`]: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.formItemCalculation}`]: {
    gap: '20px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px',
  },
  [`& .${classes.submitBtn}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
    flexDirection: 'row-reverse',
    marginTop: '40px',
  },
}))

interface Props {
  open: boolean
  onClose: () => void
  dueAmountsData: AccountDueAmountResult
  dueAmountDataIsSuccess: boolean
  accountsData: any
}

const DueAmountsModal = ({ open, onClose, dueAmountsData, dueAmountDataIsSuccess, accountsData }: Props) => {
  const { dealID } = useParams()
  const [initialValues, setInitialValues] = useState<any>({})
  const [getAccountDueAmount, { data, isSuccess }] = useGetAccountDueAmountMutation()
  const [getTime] = useGetTimeMutation()

  const handleSubmit = (values?: any) => {
    if (dealID) {
      const isQuote = convertDateToString(values.EffectiveDateDueAmounts) !== getCurrentDate()
      getAccountDueAmount({
        dealId: dealID as string,
        accountDueAmount: {
          effectiveDate: convertDateToString(values.EffectiveDateDueAmounts) as string,
          isQuote,
          isLeaseReturn: values.isLeaseReturn,
        },
      })
      setInitialValues({
        ...initialValues,
        EffectiveDateDueAmounts: values.EffectiveDateDueAmounts,
        isLeaseReturn: values.isLeaseReturn,
      })
    }
  }

  useEffect(() => {
    // Get the data from parent component (AccountsOverview)
    if (open)
      fetchCurrentDateTimeFromServer(getTime).then((currentDate) => {
        setInitialValues({
          ...initialValues,
          EffectiveDateDueAmounts: currentDate,
          PastDueAmount: dueAmountsData?.PastDueAmount,
          PayoffAmount: dueAmountsData?.PayoffAmount,
          PastDueCalculation: dueAmountsData?.PastDueCalculation,
          PayoffCalculation: dueAmountsData?.PayoffCalculation,
        })
        // Set the new values after calling getAccountDueAmount
        if (isSuccess) {
          setInitialValues({
            ...initialValues,
            PastDueAmount: data?.PastDueAmount,
            PayoffAmount: data?.PayoffAmount,
            PastDueCalculation: data?.PastDueCalculation,
            PayoffCalculation: data?.PayoffCalculation,
          })
        }
      })
  }, [data, dueAmountDataIsSuccess, isSuccess, open])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Due Amounts</DialogTitle>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit, form, values }: any) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              {accountsData.AccountStatus == 'Active' && accountsData.DealType == 'Lease' && accountsData.IsServicedByCompany == true && (
                <Field name='isLeaseReturn'>
                  {(props) => (
                    <div>
                      <FormControlLabel
                        sx={{ m: 2, top: 5, position: 'absolute', right: 20 }}
                        control={
                          <Switch
                            name={props.input.name}
                            checked={props.input.value}
                            onChange={(e) => {
                              props.input.onChange(e.target.checked)
                              form.submit()
                            }}
                          />
                        }
                        label='Lease Return'
                      />
                    </div>
                  )}
                </Field>
              )}
              <Grid container spacing={2} py={1}>
                <Grid item xs={12}>
                  <DateField
                    fullWidth
                    onDateChange={handleSubmit}
                    disableManualUserInput={true}
                    // defaultValue={getCurrentDate()}
                    name='EffectiveDateDueAmounts'
                    label='Effective Date'
                    helperText={data?.CalculationErrorDescription}
                  />
                </Grid>
                <Grid container item spacing={2} xs={12}>
                  <Grid item xs={3}>
                    <Typography variant='body2'>Past Due Amount</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant='body2'>{formatNumber(values?.PastDueAmount)}</Typography>
                  </Grid>
                </Grid>
                {/* <AmountField fullWidth variant='standard' name='PastDueAmount' label='Past Due Amount' disabled sx={{ width: '100%' }} /> */}
                <Grid container item spacing={2} xs={12}>
                  <Grid item xs={3}>
                    <Typography variant='body2'>Payoff Amount</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant='body2'>{formatNumber(values?.PayoffAmount)}</Typography>
                  </Grid>
                  {/* <AmountField fullWidth variant='standard' name='PayoffAmount' label='Payoff Amount' disabled sx={{ width: '100%' }} /> */}
                </Grid>
                <Grid container item spacing={2} xs={12}>
                  <Grid item xs={3}>
                    <Typography variant='body2'>Past Due Calculation</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant='body2'>{values?.PastDueCalculation}</Typography>
                  </Grid>
                  {/* <TextField multiline name='PastDueCalculation' label='Past Due Calculation' fullWidth disabled /> */}
                </Grid>
                <Grid container item spacing={2} xs={12}>
                  <Grid item xs={3}>
                    <Typography variant='body2'>Payoff Calculation</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant='body2'>{values?.PayoffCalculation}</Typography>
                  </Grid>
                  {/* <TextField multiline name='PayoffCalculation' label='Payoff Calculation' fullWidth disabled /> */}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions />
          </form>
        )}
      </Form>
    </Dialog>
  )
}

export default DueAmountsModal
