import { Button, Card, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectDropdownByNameActiveOnly, usePurchaseAccountsMutation } from '../../../../../services'
import { AmountField, DropdownCheckboxes, DropdownField, TextField } from '../../../../../ui'
import { Form, useForm, useFormState } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import removeMask from '../../../../../utils/removeMask'

function Dealer({}: {}) {
  const branch = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Branch'))
  const dealer = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Dealer'))
  const [purchaseAccounts, { data: purchaseAccountsData, isLoading: purchaseAccountsIsLoading, isSuccess, error }] = usePurchaseAccountsMutation()
  const { change } = useForm()
  const { values } = useFormState()

  // useEffect(() => {
  //   // if Mid-Stream Loan” is toggled off then:
  //   if (!values.midStream) {
  //     //     if “Discount Type” = “Rate” then “Discount Amount” = round(“Amount Financed” * “Discount %”/100,2)
  //     if (values.discountType == 'Rate') {
  //       change('discountAmount', ((Number(removeMask(values.amountFinanced)) * Number(removeMask(values.discountRate))) / 100).toFixed(2).toString())
  //     }
  //     // if “Discount Type” = “Amount” then “Discount Rate” = round(“Discount Amount” / “Amount Financed” * 100,2)
  //     if (values.discountType == 'Amount' && Number(removeMask(values.discountAmount)) && Number(removeMask(values.amountFinanced))) {
  //       change('discountRate', ((Number(removeMask(values.discountAmount)) / Number(removeMask(values.amountFinanced))) * 100).toFixed(2).toString())
  //     }

  //     // “Dealer Proceed” = “amount financed” - “discount amount” - “dealer reserve 1” - “dealer reserve 2” - “dealer reserve 3” - “dealer reserve 4”
  //     change(
  //       'dealerProceed',
  //       (
  //         Number(removeMask(values.amountFinanced)) -
  //         Number(removeMask(values.discountAmount)) -
  //         Number(removeMask(values.reserveAmount1)) -
  //         Number(removeMask(values.reserveAmount2)) -
  //         Number(removeMask(values.reserveAmount3)) -
  //         Number(removeMask(values.reserveAmount4))
  //       ).toString(),
  //     )
  //   }
  //   // if Mid-Steam Loan” is toggled ON AND Interest Method = “IB” then
  //   if (values.midStream && values?.interestMethod == 'IB') {
  //     // if “Discount Type” = “Rate” then “Discount Amount” = round(“Principal Balance” * “Discount %”/100,2)
  //     if (values.discountType == 'Rate') {
  //       change(
  //         'discountAmount',
  //         ((Number(removeMask(values.principalBalance)) * Number(removeMask(values.discountRate))) / 100).toFixed(2).toString(),
  //       )
  //     }
  //     // if “Discount Type” = “Amount” then “Discount Rate” = round(“Discount Amount” / “Principal Balance” * 100,2)
  //     if (values.discountType == 'Amount' && Number(removeMask(values.discountAmount)) && Number(removeMask(values.amountFinanced))) {
  //       change(
  //         'discountRate',
  //         ((Number(removeMask(values.discountAmount)) / Number(removeMask(values.principalBalance))) * 100).toFixed(2).toString(),
  //       )
  //     }
  //     // “Dealer Proceed” = “Principal Balance” - “discount amount” - “dealer reserve 1” - “dealer reserve 2” - “dealer reserve 3” - “dealer reserve 4”
  //     change(
  //       'dealerProceed',
  //       (
  //         Number(removeMask(values.principalBalance)) -
  //         Number(removeMask(values.discountAmount)) -
  //         Number(removeMask(values.reserveAmount1)) -
  //         Number(removeMask(values.reserveAmount2)) -
  //         Number(removeMask(values.reserveAmount3)) -
  //         Number(removeMask(values.reserveAmount4))
  //       ).toString(),
  //     )
  //   }

  //   // if Mid-Steam Loan” is toggled ON AND Interest Method = “PC” then
  //   if (values.midStream && values?.interestMethod == 'PC') {
  //     // principal_balance = “Gross Balance” - “Finance Charges” + “Interest Paid”
  //     change(
  //       'principalBalance',
  //       (
  //         Number(removeMask(values.balanceRemaining || '0')) -
  //         Number(removeMask(values.financeCharges)) +
  //         Number(removeMask(values.interestPaid || '0'))
  //       ).toString(),
  //     )
  //     // if principal_balance < “Gross Balance” then principal_balance = “Gross Balance”
  //     //   if (Number(removeMask(values.principalBalance)) < (Number(removeMask(values.balanceRemaining)) ?? '0')) {
  //     //     change('principalBalance', Number(removeMask(values.balanceRemaining)))
  //     //   }

  //     // if “Discount Type” = “Rate” then “Discount Amount” = round(principal_balance * “Discount %”/100,2)
  //     if (values.discountType == 'Rate') {
  //       let pb = values.principalBalance
  //       if (pb > (Number(removeMask(values.balanceRemaining)) ?? '0')) {
  //         pb = Number(removeMask(values.balanceRemaining ?? '0'))
  //       }

  //       change('discountAmount', ((pb * Number(removeMask(values.discountRate))) / 100).toString())
  //     }

  //     // if “Discount Type” = “Amount” then “Discount Rate” = round(“Discount Amount” / principal_balance * 100,2)
  //     if (values.discountType == 'Amount') {
  //       change(
  //         'discountRate',
  //         ((Number(removeMask(values.discountAmount)) / Number(removeMask(values.principalBalance))) * 100).toFixed(2).toString(),
  //       )
  //     }

  //     // “Dealer Proceed” = “principal_balance” - “discount amount” - “dealer reserve 1” - “dealer reserve 2” - “dealer reserve 3” - “dealer reserve 4”
  //     change(
  //       'dealerProceed',
  //       (
  //         Number(removeMask(values.principalBalance)) -
  //         Number(removeMask(values.discountAmount)) -
  //         Number(removeMask(values.reserveAmount1)) -
  //         Number(removeMask(values.reserveAmount2)) -
  //         Number(removeMask(values.reserveAmount3)) -
  //         Number(removeMask(values.reserveAmount4))
  //       ).toString(),
  //     )
  //   }
  // }, [values])

  useEffect(() => {
    let principal_balance: string | number = 0

    // Determine principal_balance based on conditions

    if (values.pip) {
      principal_balance = (values.paymentAmount * values.paymentsPurchased).toFixed(2)
    } else if (!values.midStream) {
      principal_balance = Number(removeMask(values.amountFinanced))
    } else if (values.midStream && values?.interestMethod === 'IB') {
      principal_balance = Number(removeMask(values.principalBalance))
    } else if (values.midStream && values?.interestMethod === 'PC') {
      principal_balance =
        Number(removeMask(values.balanceRemaining || '0')) -
        Number(removeMask(values.financeCharges)) +
        Number(removeMask(values.interestPaid || '0'))

      // Ensure principal_balance does not exceed Gross Balance
      if (principal_balance > Number(removeMask(values.balanceRemaining || '0'))) {
        principal_balance = Number(removeMask(values.balanceRemaining || '0'))
      }
    }

    // Calculate Discount Amount if Discount Type = "Rate"
    if (values.discountType === 'Rate') {
      change('discountAmount', ((Number(principal_balance) * Number(removeMask(values.discountRate))) / 100).toFixed(2).toString())
    }

    // Calculate Discount Rate if Discount Type = "Amount"
    if (values.discountType === 'Amount' && Number(removeMask(values.discountAmount)) && principal_balance) {
      change('discountRate', ((Number(removeMask(values.discountAmount)) / Number(principal_balance)) * 100).toFixed(2).toString())
    }

    // Calculate Dealer Proceed
    change(
      'dealerProceed',
      (
        Number(principal_balance) -
        Number(removeMask(values.discountAmount)) -
        Number(removeMask(values.reserveAmount1)) -
        Number(removeMask(values.reserveAmount2)) -
        Number(removeMask(values.reserveAmount3)) -
        Number(removeMask(values.reserveAmount4))
      ).toString(),
    )
  }, [values])

  return (
    <Card>
      <CardHeader title='Dealer' />
      <Grid container p={3} spacing={2}>
        {/* {branch && branch.length > 1 ? (
          <Grid item xs={12} sm={6}>
            <DropdownFieldSearch
              variant='outlined'
              name='Branch'
              label='Branch'
              options={branch}
              optionKey={'BranchID'}
              optionValue={'Branch'}
              fullWidth
            />
          </Grid>
        ) : (
          <></>
        )} */}
        {dealer && (
          <Grid item xs={12} sm={6}>
            <DropdownFieldSearch
              onChange={(val) => {
                let currDealer = dealer.find((el) => el.ID == val)
                console.log(currDealer)
                change('discountRate', currDealer?.DiscountRate.toString())
                change('discountAmount', currDealer?.DiscountAmount.toString())
                change('reserveAmount1', currDealer?.ReserveAmount1.toString())
                change('reserveAmount2', currDealer?.ReserveAmount2.toString())
                change('reserveAmount3', currDealer?.ReserveAmount3.toString())
                change('reserveAmount4', currDealer?.ReserveAmount4.toString())
              }}
              name='Dealer'
              label='Dealer'
              options={dealer}
              optionKey={'ID'}
              optionValue={'Name'}
              fullWidth
            />
          </Grid>
        )}
        {values?.loanServicing == false && (
          <>
            <Grid item xs={12} sm={6}>
              <DropdownFieldSearch
                name='discountType'
                label='Discount Type'
                options={[{ value: 'Rate' }, { value: 'Amount' }]}
                optionKey={'value'}
                optionValue={'value'}
                fullWidth
              />
            </Grid>

            {values.discountType == 'Rate' ? (
              <Grid item xs={12} sm={6}>
                <AmountField
                  disabled={values.discountType == 'Amount'}
                  variant='standard'
                  name='discountRate'
                  label='Discount Rate'
                  fullWidth
                  autoComplete='off'
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={6}>
                <AmountField
                  disabled={values.discountType == 'Rate'}
                  variant='standard'
                  name='discountAmount'
                  label='Discount Amount'
                  fullWidth
                  autoComplete='off'
                />
              </Grid>
            )}

            {values.discountType == 'Amount' ? (
              <Grid item xs={12} sm={6}>
                <AmountField
                  variant='standard'
                  disabled={values.discountType == 'Amount'}
                  name='discountRate'
                  label='Discount Rate'
                  fullWidth
                  autoComplete='off'
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={6}>
                <AmountField
                  disabled={values.discountType == 'Rate'}
                  variant='standard'
                  name='discountAmount'
                  label='Discount Amount'
                  fullWidth
                  autoComplete='off'
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <AmountField variant='standard' fullWidth name='reserveAmount1' label='Reserve Amount 1' />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AmountField variant='standard' fullWidth name='reserveAmount2' label='Reserve Amount 2' />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AmountField variant='standard' fullWidth name='reserveAmount3' label='Reserve Amount 3' />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AmountField variant='standard' fullWidth name='reserveAmount4' label='Reserve Amount 4' />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AmountField variant='standard' fullWidth name='dealerProceed' disabled label='Dealer Proceed' />
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  )
}

export default Dealer
