import dayjs from 'dayjs'
import * as yup from 'yup'

export const addDealLabels = {
  DealType: 'Deal Type',
  State: 'State',
  TaxCounty: 'Tax County',
  SalesPrice: 'Sales Price',
  TradeAllowance: 'Trade Allowance',
  TradeLien: 'Trade Lien',
  TradeACV: 'Trade ACV',
  CashDown: 'Cash Down',
  PickupPayments: 'Pickup Payments',
  StateTaxRate: 'State Tax Rate',
  CityTaxRate: 'City Tax Rate',
  CountyTaxRate: 'County Tax Rate',
  MiscTaxRate: 'Misc Tax Rate',
  ZipCode: 'Zip Code',
  TaxCity: 'Tax City',
  AmountFinanced: 'Amount Financed',
  InterestRate: 'Interest Rate',
  DealDate: 'Deal Date',
  FirstDueDate: 'First Due Date',
  Term: 'Term',
  Schedule: 'Payment Schedule',
  DesiredPayment: 'Payment',
  DesiredTerm: 'Term',
}

export const addDealsReferenceLabels = {
  FirstName: 'First Name',
  MiddleName: 'Middle Name',
  LastName: 'Last Name',
  BusinessName: 'Business Name',
  EmploymentCurrentName: 'Name',
  IncomeMonthlyIncome: 'Income',
  ApplicantEmail: 'Email',
  EmploymentCurrentWorkEmail: 'Email',
  Email: 'Email',
  RelationshipTypeID: 'Relationship',
}

const fetchCurrentDate = async () => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const date = new Date()
  const systemDate = date?.toDateString()
  const data = await fetch('https://worldtimeapi.org/api/timezone/' + tz).then((response) => response.json())
  if (data) {
    return data?.datetime
  } else {
    return systemDate
  }
}

const today = dayjs().endOf('day')
export const addDealValidation = (currentDate:string): yup.SchemaOf<any> =>  {
  return  yup
.object({
    DealType: yup.string().nullable().required().label(addDealLabels.DealType),
    DealState: yup.string().nullable().required().label(addDealLabels.State),
    TaxCounty: yup
      .string()
      .nullable()
      .when('DealType', {
        is: 'Wholesale',
        then: yup.string().nullable(),
        otherwise: yup.string().nullable().required().label(addDealLabels.TaxCounty),
      }),
    TaxCity: yup
      .string()
      .nullable()
      .when('DealType', {
        is: 'Wholesale',
        then: yup.string().nullable(),
        otherwise: yup.string().nullable().required().label(addDealLabels.TaxCity),
      }),
    SalesPrice: yup.string().nullable().required().label(addDealLabels.SalesPrice),
    CashDown: yup.string().nullable().required().label(addDealLabels.CashDown),
    StateTaxRate: yup
      .string()
      .nullable()
      .when('DealType', {
        is: 'Wholesale',
        then: yup.string().nullable(),
        otherwise: yup.string().nullable().required().label(addDealLabels.StateTaxRate),
      }),
    CityTaxRate: yup
      .string()
      .nullable()
      .when('DealType', {
        is: 'Wholesale',
        then: yup.string().nullable(),
        otherwise: yup.string().nullable().required().label(addDealLabels.CityTaxRate),
      }),
    CountyTaxRate: yup
      .string()
      .nullable()
      .when('DealType', {
        is: 'Wholesale',
        then: yup.string().nullable(),
        otherwise: yup.string().nullable().required().label(addDealLabels.CountyTaxRate),
      }),
    MiscTaxRate: yup
      .string()
      .nullable()
      .when('DealType', {
        is: 'Wholesale',
        then: yup.string().nullable(),
        otherwise: yup.string().nullable().required().label(addDealLabels.MiscTaxRate),
      }),
    InterestRate: yup.string().nullable().required().label(addDealLabels.InterestRate),
    DealDate: yup.date().typeError('Invalid Date').max(new Date(currentDate), 'Future date not allowed').nullable().required(),
    FirstDueDate: yup
      .date()
      .nullable()
      .required()
      .typeError('Invalid Date')
      .label(addDealLabels.FirstDueDate)
      .test('match-due-days', 'Due Date must fall on either 1st Due Day or 2nd Due Day', function (value) {
        const { DueDay1, DueDay2, Schedule } = this.parent
        if (!value) return false
        const dueDate = dayjs(value)
        if (Schedule === 'S') {
          return dueDate.date() === Number(DueDay1) || dueDate.date() === Number(DueDay2)
        } else {
          return true
        }
      }),
    Schedule: yup.string().nullable().required().label(addDealLabels.Schedule),
    DesiredPayment: yup
      .string()
      .nullable()
      .notRequired()
      .when('Calculate', {
        is: 'term',
        then: yup.string().nullable().required().min(0.01).label(addDealLabels.DesiredPayment),
      }),
    DesiredTerm: yup
      .string()
      .nullable()
      .notRequired()
      .when('Calculate', {
        is: 'payment',
        then: yup.string().nullable().required().label(addDealLabels.DesiredTerm),
      }),
  })
  .required()}

export const addDealsReferenceValidation: yup.SchemaOf<any> = yup
  .object({
    RelationshipTypeID: yup.string().required().label(addDealsReferenceLabels.RelationshipTypeID),
    FirstName: yup
      .string()
      .nullable()
      .notRequired()
      .when('AccountType', {
        is: 'individual',
        then: yup.string().nullable().required().label(addDealsReferenceLabels.FirstName),
      }),
    LastName: yup
      .string()
      .nullable()
      .notRequired()
      .when('AccountType', {
        is: 'individual',
        then: yup.string().nullable().required().label(addDealsReferenceLabels.LastName),
      }),
    BusinessName: yup
      .string()
      .nullable()
      .notRequired()
      .when('AccountType', {
        is: 'business',
        then: yup.string().required().label(addDealsReferenceLabels.BusinessName),
      }),
    Email: yup.string().email().nullable().label(addDealsReferenceLabels.Email),
  })
  .required()
