import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Switch,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  Typography,
} from '@mui/material'
import { appRoute } from '../../../constants'
import Iconify from '../../../components/iconify'
import BuyerItem from '../../Leads/LeadsList/components/buyer-item'
import EditIcon from '@mui/icons-material/Edit'
import { Key, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { usePopover } from '../../../components/custom-popover'
import PayHistoryRecord from '../AccountsPayHistoryNew/components/PayHistoryRecord/PayHistoryRecord'
import { UspPaymentViewDetailResult } from '../../../models'
import HistoryItem from './components/history-item'
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  emptyRows,
  useTable,
} from '../../../components/table'
import Scrollbar from '../../../components/scrollbar'
import OrderTableRow from './components/history-row'
import { convertDateToString } from '../../../utils/dateUtils'
import { useGetTimeMutation } from '../../../services'
import { fetchCurrentDateTimeFromServer } from '../../../utils/general'

export default function AccountsHistory({ data, getPaymentsDetails, paymentDetailsData, setRefetch, getAccountsOverview, getAccountDueAmount }: any) {
  const navigate = useNavigate()
  const [includeVoidedPayments, setIncludeVoidedPayments] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [currentDate, setCurrentDate] = useState<string | null>(null)
  const { dealID } = useParams()
  const [getTime] = useGetTimeMutation()

  const table = useTable()
  const denseHeight = table.dense ? 56 : 56 + 20
  const notFound = !paymentDetailsData?.length

  const fetchCurrentDate = () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const date = new Date()
    const systemDate = date?.toDateString()
    // fetch('https://worldtimeapi.org/api/timezone/' + tz)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data) {
    //       setCurrentDate(convertDateToString(data?.datetime))
    //     } else {
    setCurrentDate(convertDateToString(systemDate))
    // }
    // })
  }
  useEffect(() => {
    fetchCurrentDate()
  }, [])

  useEffect(() => {
    const date = new Date()
    getPaymentsDetails({
      dealId: dealID as string,
      uspPaymentView: {
        isIncludeReversals: includeVoidedPayments,
      },
    })
    // fetchCurrentDateTimeFromServer(getTime).then((currentDate) => {
    //   getAccountDueAmount({
    //     dealId: dealID as string,
    //     accountDueAmount: {
    //       effectiveDate: convertDateToString(currentDate as string) ?? '',
    //       isQuote: false,
    //     },
    //   })
    // })
    console.log('fetch')
  }, [includeVoidedPayments, refresh])

  const sortedPaymentDetailsData = useMemo(() => {
    return (paymentDetailsData || [])?.slice()?.sort((a: any, b: any) => b.PaymentDetailID - a.PaymentDetailID)
  }, [paymentDetailsData])

  const TABLE_HEAD = [
    { id: 'PaymentType', label: 'Payment' },
    { id: 'PaymentDate', label: 'Effective' },
    { id: 'Cashier', label: 'By' },
    { id: 'PaymentForm', label: 'Form' },
    { id: 'TransactionAmount', label: 'Total' },
    { id: 'ProcessingFee', label: 'Processing' },
    { id: 'Created', label: 'Created' },
    { id: 'Transaction', label: 'Transaction' },
    { id: 'PaymentStatus', label: 'Status' },
    { id: '' },
  ]
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ height: '100%' }}>
            <Stack spacing={2.5} sx={{ p: 3 }}>
              {
                // sortedPaymentDetailsData?.map((item) => (

                //   <HistoryItem
                //     includeVoidedPayments={includeVoidedPayments}
                //     getPaymentsDetails={getPaymentsDetails}
                //     dealID={data?.DealID}
                //     branchID={data?.BranchID}
                //     recordData={item as UspPaymentViewDetailResult}
                //     expandHistoryRecords={expandHistoryRecords}
                //   />
                // ))
                <Box>
                  <Stack direction={'row'} justifyContent={'flex-end'} sx={{ pb: 1 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          title='sd'
                          checked={includeVoidedPayments}
                          onChange={(e: any) => {
                            setIncludeVoidedPayments(e.target.checked)
                          }}
                        />
                      }
                      labelPlacement='start'
                      label='Include Voided Payments'
                    />
                  </Stack>
                  <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                    <Scrollbar>
                      <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                        <TableHeadCustom
                          order={table.order}
                          orderBy={table.orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={sortedPaymentDetailsData.length}
                          numSelected={table.selected.length}
                          onSort={table.onSort}
                        />
                        <TableBody>
                          {sortedPaymentDetailsData
                            // .slice(table.page * table.rowsPerPage, table.page * table.rowsPerPage + table.rowsPerPage)
                            .map((row: any) => (
                              <OrderTableRow
                                key={row.PaymentID}
                                row={row}
                                data={data}
                                getPaymentsDetails={getPaymentsDetails}
                                setRefresh={setRefresh}
                                getAccountsOverview={getAccountsOverview}
                                getAccountDueAmount={getAccountDueAmount}
                                // selected={table.selected.includes(row.id)}
                                // onSelectRow={() => table.onSelectRow(row.id)}
                                // onDeleteRow={() => handleDeleteRow(row.id)}
                                // onViewRow={() => handleViewRow(row.id)}
                              />
                            ))}

                          <TableEmptyRows
                            height={denseHeight}
                            emptyRows={emptyRows(table.page, table.rowsPerPage, sortedPaymentDetailsData.length)}
                          />

                          <TableNoData notFound={notFound} />
                        </TableBody>
                      </Table>
                    </Scrollbar>
                  </TableContainer>

                  {/* <TablePaginationCustom
                    count={sortedPaymentDetailsData.length}
                    page={table.page}
                    rowsPerPage={table.rowsPerPage}
                    onPageChange={table.onChangePage}
                    onRowsPerPageChange={table.onChangeRowsPerPage}
                    //
                    dense={table.dense}
                    onChangeDense={table.onChangeDense}
                  /> */}
                </Box>
              }
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
