import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Paper, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { AmountField, DateField, TextField } from '../../../../../ui'
import { selectDropdownByName, useAddAccountSideNoteMutation, useGetTimeMutation } from '../../../../../services'
import { useLocation, useParams } from 'react-router-dom'
import { convertStringToDate } from '../../../../../utils/dateUtils'
import removeMask from '../../../../../utils/removeMask'
import { fetchCurrentDateTimeFromServer, getCurrentDate } from '../../../../../utils/general'
import validateFormValues from '../../../../../utils/validateForm'
import { accountsAddSideNoteValidation } from '../../../../../validation/accountsAddSideNoteValidation'
import { useDispatch, useSelector } from 'react-redux'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { AccountsDropdownResult, SideNoteType } from '../../../../../models'

const classes = generateUtilityClasses('Box', ['root', 'row'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.secondary,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  open: boolean
  onClose: () => void
  getAccountNotesHistory: (arg: any) => void
  getAccountsOverview: (arg: any) => void
  getAccountsPayHistory: (arg: any) => void
  getAccountDueAmountRequest: (e: any) => void
  includeDeletedNotes: boolean
}

const AddSideNoteModal = ({
  open,
  onClose,
  getAccountsOverview,
  getAccountNotesHistory,
  getAccountDueAmountRequest,
  includeDeletedNotes,
  getAccountsPayHistory,
}: Props) => {
  const [addSideNote, { isLoading, isSuccess }] = useAddAccountSideNoteMutation()
  const { dealID } = useParams()
  const location = useLocation()
  const dispatch = useDispatch()
  const sideNoteType = useSelector((state) => selectDropdownByName(state, 'SideNoteType'))
  const [getTime] = useGetTimeMutation()

  const [initialValues, setInitialValues] = useState({})

  useEffect(() => {
    if (open)
      fetchCurrentDateTimeFromServer(getTime).then((currentDate) => {
        setInitialValues({
          FirstDueDate: convertStringToDate(currentDate as string) ?? '',
        })
      })
  }, [open])

  const handleSubmit = (values?: any) => {
    fetchCurrentDateTimeFromServer(getTime).then((currentDate) => {
      addSideNote({
        dealId: dealID as string,
        uspAccountSideNoteAdd: {
          effectiveDate: convertStringToDate(currentDate as string) ?? '',
          transactionAmount: removeMask(values.Amount) as string,
          note: values?.Note,
          sideNoteID: values?.Type,
          sideNoteFirstDueDate: convertStringToDate(values.FirstDueDate) as string,
        },
      })
    })
  }

  useEffect(() => {
    if (isLoading) {
      dispatch(enqueueNotification({ message: 'Success' }))
      onClose()
      if (location.pathname.includes(`/accounts/${dealID}/note-history`)) {
        getAccountNotesHistory({
          accountNotesHistory: {
            dealID: parseInt(dealID as string),
            isIncludeDeletedNotes: includeDeletedNotes,
            isImportantMessagesOnly: false,
            creditAppID: null,
            contactID: null,
          },
        })
      }
      if (location.pathname.includes(`/accounts/${dealID}/overview`)) {
        getAccountsOverview({ dealId: dealID as string })
      }
      if (location.pathname.includes(`/accounts/${dealID}/pay-history`)) {
        getAccountsPayHistory({
          dealId: dealID as string,
          accountPayHistory: {
            isIncludeReversals: false,
          },
        })
      }
    }
  }, [isLoading])

  useEffect(() => {
    if (isSuccess) {
      if (location.pathname.includes(`/accounts/${dealID}/overview`)) {
        getAccountsOverview({ dealId: dealID as string })
        fetchCurrentDateTimeFromServer(getTime).then((currentDate) => {
          getAccountDueAmountRequest({
            dealId: dealID as string,
            accountDueAmount: {
              effectiveDate: convertStringToDate(currentDate as string) ?? '',
              isQuote: false,
            },
          })
        })
      }
    }
  }, [isSuccess])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Side Note</DialogTitle>

      <Form onSubmit={handleSubmit} initialValues={initialValues} validate={validateFormValues(accountsAddSideNoteValidation)}>
        {({ handleSubmit, valid }: any) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2} py={1}>
                <Grid item xs={12}>
                  {sideNoteType && (
                    <DropdownFieldSearch
                      name='Type'
                      label='Type'
                      options={sideNoteType?.ReturnData}
                      optionKey='ID'
                      optionValue='SideNoteLabel'
                      fullWidth
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <AmountField variant='standard' name='Amount' label='Amount' fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <DateField name='FirstDueDate' label='Due Date' disablePast fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <TextField multiline name='Note' label='Note' fullWidth />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button variant='contained' color='error' onClick={onClose}>
                Cancel
              </Button>
              <Button variant='contained' type='submit' disabled={!valid}>
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  )
}

export default AddSideNoteModal
