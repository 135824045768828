import React from 'react'
import { styled } from '@mui/system'
import { Box, CardHeader, Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { useFormState } from 'react-final-form'
import { DateField, PercentField, TextField } from '../../../../../../../ui'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import AmountField from '../../../../../../../ui/AmountField'
import { formatNumber } from '../../../../../../../utils/general'
import { DealItemizationDataType } from '../../../../../../../models'
import { convertDateToDisplay } from '../../../../../../../utils/dateUtils'
import removeMask from '../../../../../../../utils/removeMask'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'switchWrap', 'optionalText', 'rowBtn', 'printBtn'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.switchWrap}`]: {
    margin: theme.spacing(0, 0, 0, 5),
    padding: theme.spacing(0, 0, 0, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  itemizationData: DealItemizationDataType[] | undefined
  dealType: string | null | undefined
}

export const ResultForm = ({ itemizationData, dealType }: Props) => {
  const { values } = useFormState()

  const scheduleMapping: Record<string, string> = { W: 'Weekly', B: 'Bi-Weekly', S: 'Semi-Monthly', M: 'Monthly' }

  return (
    <>
      {/* <StyledGrid className={classes.root}>
        <Grid container>
          <Grid className={classes.row}>
            <Typography variant='subtitle1' gutterBottom>
              Deal Result
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.row}>
          <AmountField variant='standard' disabled name='AmountPaymentAmount' label='Payment' fullWidth />
        </Grid>
        {values.FinalPaymentAmount > 0 && (
          <Grid className={classes.row}>
            <AmountField variant='standard' disabled name='FinalPaymentAmount' label='Final Payment Amount' fullWidth />
          </Grid>
        )}
        {dealType === 'Financed' && (
          <Grid className={classes.row}>
            <TextField variant='outlined' disabled name='calculateTerm' label='Term' fullWidth />
          </Grid>
        )}
        {Number(values.Calculate) === 0 && (
          <Grid className={classes.row}>
            <AmountField variant='standard' disabled name='AmountFinalPayment' label='Final Payment' fullWidth />
          </Grid>
        )}
        {dealType === 'Financed' && (
          <Grid className={classes.row}>
            <AmountField variant='standard' disabled name='AmountFinanced' label='Amount Financed' fullWidth />
          </Grid>
        )}
        {dealType === 'Financed' && (
          <Grid className={classes.row}>
            <AmountField variant='standard' disabled name='AmountFinanceCharges' label='Finance Charges' fullWidth />
          </Grid>
        )}
        {dealType === 'Financed' && (
          <Grid className={classes.row}>
            <AmountField variant='standard' disabled name='TotalOfPayments' label='Total of Payments' fullWidth />
          </Grid>
        )}
        {dealType === 'Financed' && (
          <Grid className={classes.row}>
            <PercentField variant='standard' disabled name='APRRate' label='APR' fullWidth />
          </Grid>
        )}
        {(dealType === 'Financed' || dealType === 'Cash') && (
          <Grid className={classes.row}>
            <AmountField variant='standard' disabled name='AmountSalesTax' label='Sales Tax' fullWidth />
          </Grid>
        )}
        {dealType === 'Financed' && (
          <Grid className={classes.row}>
            <DateField disabled name='MaturityDate' label='Maturity Date' fullWidth />
          </Grid>
        )}
      </StyledGrid> */}
      <Grid container pb={2}>
        <CardHeader title='Itemization' />
        <Grid item xs={12}>
          <Box sx={{ p: 3, pb: 0 }} display={'flex'} flexDirection={'column'}>
            {itemizationData?.map((item: DealItemizationDataType, index: number) => (
              <Box display={'flex'} alignItems={'baseline'}>
                <Typography sx={{ py: 1 }} variant='body2'>
                  {item?.Name} :
                </Typography>
                <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                  {formatNumber(item?.Amount)}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
        <CardHeader title='Terms' />
        <Grid item xs={12}>
          <Box sx={{ p: 3, pb: 0 }} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} alignItems={'baseline'}>
              <Typography sx={{ py: 1 }} variant='body2'>
                Pay Schedule:
              </Typography>
              <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                {/* {formatNumber(values)} */}
                {scheduleMapping?.[values.Schedule as unknown as string]}
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'baseline'}>
              <Typography sx={{ py: 1 }} variant='body2'>
                Term:
              </Typography>
              <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                {/* {formatNumber(values)} */}
                {values.AmountFinalTerm}
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'baseline'}>
              <Typography sx={{ py: 1 }} variant='body2'>
                Payment:
              </Typography>
              <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                {formatNumber(values.AmountPaymentAmount as string)}
              </Typography>
            </Box>
            {Number(removeMask(values.AmountFinalPayment)) > 0 && (
              <>
                <Box display={'flex'} alignItems={'baseline'}>
                  <Typography sx={{ py: 1 }} variant='body2'>
                    Final Payment:
                  </Typography>
                  <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                    {formatNumber(values.AmountFinalPayment as string)}
                  </Typography>
                </Box>
              </>
            )}
            <Box display={'flex'} alignItems={'baseline'}>
              <Typography sx={{ py: 1 }} variant='body2'>
                Interest Rate:
              </Typography>
              <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                {/* {formatNumber(values)} */}
                {values.InterestRate}
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'baseline'}>
              <Typography sx={{ py: 1 }} variant='body2'>
                APR:
              </Typography>
              <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                {/* {formatNumber(values)} */}
                {values.APRRate}
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'baseline'}>
              <Typography sx={{ py: 1 }} variant='body2'>
                First Due Date:
              </Typography>
              <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                {/* {formatNumber(values)} */}
                {convertDateToDisplay(values.FirstDueDate)}
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={' baseline'}>
              <Typography sx={{ py: 1 }} variant='body2'>
                Maturity Date:
              </Typography>
              <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                {/* {formatNumber(values)} */}
                {convertDateToDisplay(values.MaturityDate)}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default ResultForm
