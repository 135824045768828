import { Box, Button, Card, CardHeader, IconButton, MenuItem, Typography } from '@mui/material'
import Iconify from '../../../../../../components/iconify'
import { UspCarSearchResult, UspCreditAppSearchResult, UspGetDataCar, UspGetDataCreditApp, UspGetDataDealEntryResult } from '../../../../../../models'
import CustomPopover, { usePopover } from '../../../../../../components/custom-popover'
import { applyPhoneMask } from '../../../../../../utils/general'
import AddIcon from '@mui/icons-material/Add'

type Props = {
  setCurrentStage: (stage: 'inventory' | 'credit' | 'dealStructure' | 'dealResult') => void
  creditSelected?: UspCreditAppSearchResult | UspGetDataCreditApp
  dealData: UspGetDataDealEntryResult | undefined
  setSelectedCreditApp: (creditApp?: UspCreditAppSearchResult | UspGetDataCreditApp) => void
}

const BuyerCard = ({ setCurrentStage, creditSelected, dealData, setSelectedCreditApp }: Props) => {
  const popover = usePopover()

  return (
    <>
      <Card
        sx={{
          flexGrow: { md: 1 },
          display: { md: 'flex' },
          flexDirection: { md: 'column' },
          p: 4,
          gap: 3,
        }}
      >
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h6'>Buyer</Typography>
          {!(creditSelected?.CreditAppID || creditSelected?.ContactID1) && (
            <Button onClick={() => setCurrentStage('credit')} startIcon={<AddIcon />} color='primary'>
              Buyer
            </Button>
          )}
        </Box>

        {(creditSelected?.CreditAppID || creditSelected?.ContactID1) && (
          <Card
            variant='outlined'
            sx={{
              pb: 3,
            }}
          >
            <CardHeader
              action={
                <IconButton aria-label='actions' color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                  <Iconify icon='eva:more-vertical-fill' />
                </IconButton>
              }
              title={
                <>
                  <Typography variant='subtitle1'>
                    {creditSelected
                      ? (creditSelected as UspCreditAppSearchResult).Applicant || (creditSelected as UspGetDataCreditApp).ApplicantName
                      : ''}
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    {(creditSelected as UspCreditAppSearchResult)?.PhoneNumber || (creditSelected as UspGetDataCreditApp)?.ApplicantPhone
                      ? applyPhoneMask((creditSelected as UspCreditAppSearchResult)?.PhoneNumber) ||
                        applyPhoneMask((creditSelected as UspGetDataCreditApp)?.ApplicantPhone)
                      : dealData?.PhoneNumber}
                  </Typography>
                </>
              }
            />
          </Card>
        )}
      </Card>
      <CustomPopover open={popover.open} onClose={popover.onClose} arrow='right-top' sx={{ width: 160 }}>
        <MenuItem
          onClick={() => {
            setCurrentStage('credit')
            popover.onClose()
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>

        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={() => {
            setSelectedCreditApp(undefined)
            popover.onClose()
          }}
        >
          <Iconify icon='solar:trash-bin-trash-bold' />
          Delete
        </MenuItem>
      </CustomPopover>
    </>
  )
}

export default BuyerCard
