import 'react-quill/dist/quill.snow.css'
import 'highlight.js/styles/base16/tomorrow-night.css'
// import hljs from 'highlight.js'
import 'quill/dist/quill.core.css'
import ReactQuill from 'react-quill'

import { alpha } from '@mui/material/styles'

import { EditorProps } from './types'
import { StyledEditor } from './styles'
import Toolbar, { formats } from './toolbar'

// ----------------------------------------------------------------------

export default function Editor({
  id = 'minimal-quill',
  error,
  simple = false,
  helperText,
  sx,
  children,
  forwardRef,
  ...other
}: EditorProps & { forwardRef?: any }) {
  const modules = {
    toolbar: {
      container: `#${id}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: false,
    clipboard: {
      matchVisual: false,
    },
  }

  return (
    <>
      <StyledEditor
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
            '& .ql-editor': {
              bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
            },
          }),
          ...sx,
        }}
      >
        {children}
        <Toolbar id={id} simple={simple} />

        <ReactQuill ref={forwardRef} modules={modules} formats={formats} placeholder='Write something awesome...' {...other} />
      </StyledEditor>

      {helperText && helperText}
    </>
  )
}
