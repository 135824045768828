import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import { useDeleteCreditAppMutation } from '../../../../../../services'
import { usePopover } from '../../../../../../components/custom-popover'
import { appRoute } from '../../../../../../constants'
import Label from '../../../../../../components/label/label'
import Iconify from '../../../../../../components/iconify'
import { convertDateToDisplay } from '../../../../../../utils/dateUtils'
import CustomPopover from '../../../../../../components/custom-popover/custom-popover'
import ConfirmDialog from '../../../../../../components/ConfirmDialog'

// ----------------------------------------------------------------------

type Props = {
  selected: boolean
  row: any
  onEditRow?: VoidFunction
  onSelectRow?: VoidFunction
  onDeleteRow: VoidFunction
  handleCreditApp(car: any): any
}

export default function CreditRow({ row, selected, onEditRow, onSelectRow, onDeleteRow, handleCreditApp }: Props) {
  const { name, status, email, interest, salesperson, added, updated, branch, phoneNumber } = row
  console.log(row)
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState<any>(undefined)
  const [deleteLead, { isSuccess: deleteLeadIsSuccess }] = useDeleteCreditAppMutation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleDelete = () => {
    if (deleteConfirmDialog?.id) {
      deleteConfirmDialog.leadId = deleteConfirmDialog?.id
      deleteLead(deleteConfirmDialog)
      onDeleteRow()
    }
    setDeleteConfirmDialog(undefined)
  }

  const handleEdit = (id: number | string) => {
    // TODO IMPLEMENT EDIT
    if (id) {
      navigate(appRoute.LeadsTask.replace(':leadID', String(id)))
    }
  }

  const popover = usePopover()

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell onClick={() => handleCreditApp(row)} sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
          <ListItemText
            primary={name}
            secondary={email}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled',
            }}
          />

          <Typography variant='body2' color={'text.disabled'}>
            {phoneNumber}
          </Typography>
        </TableCell>

        <TableCell onClick={() => handleCreditApp(row)}>
          <Label
            variant='soft'
            color={(status === 'Sold' && 'success') || (status === 'pending' && 'warning') || (status === 'Lost' && 'error') || 'default'}
          >
            {status}
          </Label>
        </TableCell>

        <TableCell onClick={() => handleCreditApp(row)} sx={{ whiteSpace: 'nowrap' }}>
          {interest}
        </TableCell>

        <TableCell onClick={() => handleCreditApp(row)} sx={{ whiteSpace: 'nowrap' }}>
          {salesperson}
        </TableCell>

        <TableCell onClick={() => handleCreditApp(row)} sx={{ whiteSpace: 'nowrap' }}>
          {convertDateToDisplay(added)}
        </TableCell>
        <TableCell onClick={() => handleCreditApp(row)} sx={{ whiteSpace: 'nowrap' }}>
          {convertDateToDisplay(updated)}
        </TableCell>
        <TableCell onClick={() => handleCreditApp(row)} sx={{ whiteSpace: 'nowrap' }}>
          {branch}
        </TableCell>

        <TableCell align='right' sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton onClick={() => handleCreditApp(row)}>
            <Iconify icon='ph:paperclip-light' />
          </IconButton>
        </TableCell>
      </TableRow>

      {/* <UserQuickEditForm currentUser={row} open={quickEdit.value} onClose={quickEdit.onFalse} /> */}

      <CustomPopover open={popover.open} onClose={popover.onClose} arrow='right-top' sx={{ width: 140 }}>
        <MenuItem
          onClick={() => {
            handleCreditApp(row)
            popover.onClose()
          }}
        >
          <Iconify icon='ph:paperclip-light' />
          Select
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={!!deleteConfirmDialog}
        onOk={handleDelete}
        handleClose={() => setDeleteConfirmDialog(undefined)}
        notification={'Lead will be removed'}
      />
    </>
  )
}
